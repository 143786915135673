import React, { useContext, useEffect, useRef, useState } from "react";
import { BsTelegram, BsFacebook } from "react-icons/bs";
import { RiWhatsappFill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FooterIcon from "../assets/images/download-apk.png";
import CasinoPopup from "./CasinoPopup";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import Draggable from "react-draggable";
import { FaHeadset } from "react-icons/fa6";
import { isBrowser } from "react-device-detect";
import Close from "../assets/images/icon-close.webp";
import LeftIcon from "../assets/images/icon-title.webp";
import Up from "../assets/images/Casino/7up.webp";
import Andhar from "../assets/images/Casino/andharBahar.webp";
import Fortune from "../assets/images/Casino/fortuneFlip.webp";
import Rocket from "../assets/images/Casino/rocket.webp";
import Lucky from "../assets/images/Casino/luckyBeer.webp";
import { MdSupervisorAccount } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import BetSlipContext from "../context/BetSlipContext";
import MyBets from "./MyBets";

const Footer = () => {
  const location = useLocation();
  const {
    user,
    showSidebar,
    active,
    setFooterLink,
    parly,
    parlyBet,
    setShowParlyBet,
    launchCasino,
    casinoUrl,
    setCasinoUrl,
    currentBetCount,
    setShowWidget,
    showWidget,
  } = useContext(AuthContext);
  const { showBet, setShowBet } = useContext(BetSlipContext);

  const { showBetDialog } = useContext(BetSlipContext);
  const navigate = useNavigate();
  const [casinoModel, setCasinoModel] = useState(false);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [touch, setTouch] = useState("");
  const handleTouchStart = (event) => {
    const touch = event?.touches[0];

    setStartX(touch?.clientX);
    setStartY(touch?.clientY);
  };

  const handleTouchEnd = (event) => {
    const touch = event.changedTouches[0];
    const endX = touch.clientX;
    const endY = touch.clientY;
    const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
    if (distance < 5) {
      setCasinoModel(true);
    }
  };
  let drag = {
    top: -800,
    left: -321,
    right: 0,
    bottom: 0,
  };
  const games = [
    {
      id: 1,
      name: "Fortune Flip",
      coins: 167,
      image: Fortune,
      request: {
        platForm: "PP",
        gameType: "SLOT",
        casinoType: "PP-SLOT-217",
        check: true,
      },
    },
    {
      id: 2,
      name: "Andar Bahar",
      coins: 123,
      image: Andhar,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-032",
        check: true,
      },
    },
    {
      id: 3,
      name: "7up7down",
      coins: 145,
      image: Up,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
        check: true,
      },
    },
    {
      id: 4,
      name: "Rocket",
      coins: 145,
      image: Rocket,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-041",
        check: true,
      },
    },
    {
      id: 5,
      name: "Beer",
      coins: 145,
      image: Lucky,
      request: {
        platForm: "PT",
        gameType: "SLOT",
        casinoType: "PT-SLOT-216",
        check: true,
      },
    },
  ];

  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={
          showWidget
            ? "stickyLeftContainer stickyLeftContainer2"
            : "stickyLeftContainer"
        }
      >
        {["full-market", "sports"]?.includes(
          location?.pathname?.split("/")[1]
        ) &&
          parly &&
          !isEmpty(user) &&
          parlyBet?.length > 0 && (
            <div
              onClick={() => {
                setShowParlyBet(true);
              }}
              className="betbtn3"
            >
              <i class="icon-parlay-list"></i>

              <span>{parlyBet?.length}</span>
            </div>
          )}
        {currentBetCount > 0 &&
          ["full-market", ""]?.includes(location?.pathname?.split("/")[1]) && (
            <span
              onClick={() => {
                if (!isEmpty(user)) {
                  setShowBet(true);
                } else {
                  navigate("/login");
                }
              }}
              class="icon-list"
            >
              <span>{currentBetCount}</span>
            </span>
          )}
      </div>
      {/* {scrollPosition > 250 && (
        <div
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          className="betbtn4"
        >
          <IoIosArrowUp className="p-0 m-0" size={22} />
          <span>TOP</span>
        </div>
      )} */}
      <div
        className="footer"
        style={showBetDialog ? { marginBottom: "330px" } : {}}
      >
        {(location?.pathname == "/" || location?.pathname == "/sports") &&
          !showSidebar &&
          active == "sports" && (
            <>
              {isBrowser ? (
                <div
                  // onClick={() => {
                  //   if (isEmpty(user)) {
                  //     navigate("/login");
                  //   } else {
                  //     setCasinoModel(true);
                  //   }
                  // }}
                  style={{ position: isBrowser ? "absolute" : "fixed" }}
                  className="betbtn1"
                >
                  <img src="../../assets/images/entrance.svg" alt="" />
                </div>
              ) : (
                <Draggable
                  bounds={drag}
                  onStart={handleTouchStart}
                  onStop={handleTouchEnd}
                >
                  <div
                    onClick={() => {
                      if (isEmpty(user)) {
                        navigate("/login");
                      }
                    }}
                    style={{ position: isBrowser ? "absolute" : "fixed" }}
                    className="betbtn1"
                  >
                    <img src="../../assets/images/entrance.svg" alt="" />
                  </div>
                </Draggable>
              )}
              <div    style={{position:isBrowser ? 'absolute' : 'fixed'}} class="betbtn2">
              <div id="live-chat" class="icon-chat text-24 text-white" onClick={() => {
                window.open("https://wa.me/+96560315302", "_self");
              }}></div>
              </div> 
            </>
          )}

        <div className="text-center download-apk">
          <Link to="/" className="d-block ">
            <img src={FooterIcon} alt="" />
          </Link>
          <span>v1.11 - 2022/3/8 - 2.6MB</span>
        </div>
        <div className="social-icon">
          {" "}
          <Link to="/">
            <i class="icon-telegram"></i>
          </Link>
          <Link to="/">
            <i class="icon-whatsapp"></i>
          </Link>
          <Link to="/">
            <i class="icon-facebook"></i>
          </Link>
        </div>
        <ul className="text-center p-0 m-0">
          <li
            onClick={() =>
              setFooterLink({
                status: true,
                type: "Privacy Policy",
              })
            }
          >
            <span>Privacy Policy</span>
          </li>
          <li
            onClick={() =>
              setFooterLink({
                status: true,
                type: "Terms and Conditions",
              })
            }
          >
            <span>Terms and Conditions</span>
          </li>
          <li
            onClick={() =>
              setFooterLink({
                status: true,
                type: "Rules and Regulations",
              })
            }
          >
            <span>Rules and Regulations</span>
          </li>
          <li
            onClick={() =>
              setFooterLink({
                status: true,
                type: "KYC",
              })
            }
          >
            <span>KYC</span>
          </li>
          <li
            onClick={() =>
              setFooterLink({
                status: true,
                type: "Responsible Gaming",
              })
            }
          >
            <span>Responsible Gaming</span>
          </li>
        </ul>
      </div>
      {casinoModel && (
        <div className="lobby slide-up">
          <div className="gmnheader">
            {/* <div
              className="header__img d-flex justify-content-between align-items-center"
              style={{ height: "9vw" }}
            >
              <div style={{ fontSize: "15px", marginLeft: "10px" }}>
                Lucky09
              </div>
              <span className="header__number" data-v-302cf84b="">
                2
              </span>
            </div> */}
            <div className="position-relative w-100">
              {" "}
              <img style={{ width: "45%" }} src={LeftIcon} />
              <span className="header__number" data-v-302cf84b="">
                5
              </span>
            </div>

            <div className="header__close" data-v-302cf84b="">
              <img
                className="icon-promote"
                src={Close}
                onClick={() => {
                  setCasinoModel(false);
                  setCasinoUrl("");
                }}
                alt=""
              />
            </div>
          </div>
          <main
            class={
              !isEmpty(casinoUrl)
                ? "game-grid-new slide-left"
                : "game-grid slide-left"
            }
          >
            {!isEmpty(casinoUrl) ? (
              <div className="casino-play-inner">
                <iframe
                  style={{ minHeight: "100%" }}
                  className="w-100"
                  src={casinoUrl}
                />
              </div>
            ) : (
              games.map((game, index) => (
                <div
                  id={game?.id}
                  onTouchStart={(e) => setTouch(e?.target?.id)}
                  onTouchEnd={() => setTouch("")}
                  onClick={() => launchCasino(game?.request)}
                  class={`game-card-casino slide-left ${
                    touch == game?.id ? "game-card-casino-new slide-left" : ""
                  }`}
                >
                  <div id={game?.id} class="coin-info">
                    <MdSupervisorAccount />
                    <span id={game?.id} class="coin-count">
                      167
                    </span>
                  </div>
                  <img
                    id={game?.id}
                    src={game?.image}
                    alt="Fortune Flip"
                    class="game-image"
                  />
                  <div id={game?.id} class="game-info">
                    <h2 id={game?.id} class="game-title">
                      {game?.name}
                    </h2>
                  </div>
                </div>
              ))
            )}
          </main>
        </div>
      )}
      {showBet && <MyBets setShowBet={setShowBet} />}
    </>
  );
};

export default Footer;
